import { Card } from "antd";
import { PushItemType } from "components/push_list/types";

export default function PushItem({ item }: { item: PushItemType }) {
  return (
    <Card
      type="inner"
      title={item.title}
      extra={new Date(item.created_at).toLocaleString()}
    >
      {item.message}
    </Card>
  );
}
