import React, { forwardRef } from "react";
import InputMask from "react-input-mask";
import { Input } from "antd";

interface MaskedInputProps {
    mask: string;
    maskChar?: string;
    alwaysShowMask?: boolean;
    value?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
    disabled?: boolean;
    status?: "error" | "warning";
}

// Оборачиваем InputMask в Ant Design Input
const MaskedInput = forwardRef<HTMLInputElement, MaskedInputProps>(
    ({ mask, maskChar, alwaysShowMask, ...props }, ref) => {
        return (
            <InputMask mask={mask} maskChar={maskChar} alwaysShowMask={alwaysShowMask} {...props}>
                {/*@ts-ignore*/}
                {(inputProps: any) => <Input {...(inputProps as React.InputHTMLAttributes<HTMLInputElement>)} ref={ref} />}
            </InputMask>
        );
    }
);

export default MaskedInput;
