import {
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
} from "react";
import { notification } from "antd";
import { NotificationProps } from "components/notification/types";

export interface NotificationMethods {
  openNotification: (props: NotificationProps) => void;
}

const NotificationComponent: ForwardRefRenderFunction<NotificationMethods> = (
  _,
  ref
) => {
  const [api, contextHolder] = notification.useNotification();

  const openNotification = (props: NotificationProps) => {
    const bgColor = () => {
      return props.style === "success"
        ? "#99cc33"
        : props.style === "warning"
        ? "#ffcc00"
        : props.style === "error"
        ? "#fa7777"
        : "#ffffff";
    };

    api.open({
      message: props.title,
      description: props.description,
      duration: props.duration,
      style: {
        backgroundColor: `${bgColor()}`,
        overflow: "hidden",
      },
    });
  };

  useImperativeHandle(ref, () => ({
    openNotification,
  }));

  return <>{contextHolder}</>;
};

export default forwardRef(NotificationComponent);
