import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Flex, Button, Input, Checkbox, Upload, Avatar, message, Form, FormProps, Typography } from "antd";
import type { UploadFile, UploadProps } from "antd/es/upload/interface";
import { sha1 } from "js-sha1";
import { md5 } from "js-md5";
import { AppFormType, AppPayloadType, EmptyAppForm } from "components/new_app/types";
import { prepareLogoUpload, uploadLogo, addMiniApp, editMiniApp, updateMiniApp } from "api/miniapps";
import { useSharedState } from "sharedStateProvider";
import { ArrowLeftOutlined, PlusOutlined } from "@ant-design/icons";

export default function NewApp() {
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const { Title, Text } = Typography;

  const {
    initGetMiniApps,
    editApp,
    setEditApp,
    bridgeMethodList,
    showNotification,
    isLoadingOverlay,
    setLoadingOverlay,
  } = useSharedState();

  const [changeLogo, setChangeLogo] = useState<boolean>(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [file, setFile] = useState<File | null>(null);

  const [appForm, setAppForm] = useState<AppFormType>(EmptyAppForm);

  const isValidUrl = new RegExp(
    "^(https:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator

  const customPreviewFile = async (file: File | Blob): Promise<string> => {
    // Your custom preview logic here
    const previewUrl = await getCustomPreviewUrl(file);
    return previewUrl;
  };

  const getCustomPreviewUrl = (file: File | Blob): Promise<string> => {
    // Your custom logic to generate a preview URL
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result as string);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const uploadProps: UploadProps = {
    multiple: false,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: async (file) => {
      // Your custom logic to determine whether to allow the upload
      const isJPG = file.type === "image/jpeg";
      if (!isJPG) {
        message.error(`Неверный формат файла ${file.name}`);
        return Upload.LIST_IGNORE;
      } else {
        // Trigger the preview logic manually
        const previewUrl = await customPreviewFile(file);
        const newFileList = [{ ...file, thumbUrl: previewUrl }];
        setFileList(newFileList);
        setFile(file);
        // Return false to prevent automatic upload
        return false;
      }
    },
    onChange: ({ fileList: newFileList }) => {
      setFileList(newFileList);
    },
    showUploadList: { showPreviewIcon: false },
    fileList: fileList,
    listType: "picture-card",
    accept: "image/jpeg",
    maxCount: 1,
  };

  const handleLogoChange = () => {
    if (!changeLogo) {
      setChangeLogo(true);
    } else {
      setChangeLogo(false);
      setFileList([]);
      setFile(null);
    }
  };

  const getLogoLink = async () => {
    // @ts-ignore
    return new Promise((resolve, reject) => {
      if (file != null) {
        const reader = new FileReader();
        reader.onload = async (e) => {
          if (e.target && e.target.result) {
            const fileContent = e.target.result;
            // @ts-ignore
            const md5Result = md5(fileContent);
            // @ts-ignore
            const sha1Result = sha1(fileContent);
            const logoInfo: object = {
              filename: file.name,
              md5: md5Result,
              sha1: sha1Result,
              public: true,
              type: file.type,
            };
            const res: object = await prepareLogoUpload(logoInfo);
            if (res.hasOwnProperty("id")) {
              //file exists
              // @ts-ignore
              resolve(res.id);
            } else {
              // @ts-ignore
              const url = new URL(res.upload_url, "http://localhost");
              const p = url.searchParams;
              const fd = new FormData();
              // @ts-ignore
              fd.append("file", fileList[0].originFileObj);
              const query =
                `?signature=${p.get("signature")}&expires_at=${p.get("expires_at")}` +
                `&filename=${p.get("filename")}&sha1=${p.get("sha1")}&md5=${p.get("md5")}&file_type=${p.get(
                  "file_type"
                )}&public=${p.get("public")}`;
              const uploadRes: object = await uploadLogo(query, fd);
              if (uploadRes.hasOwnProperty("id")) {
                // @ts-ignore
                resolve(uploadRes.id);
              } else {
                reject("");
              }
            }
          }
        };
        // @ts-ignore
        reader.readAsArrayBuffer(file);
      } else {
        throw new Error("File is null");
      }
    }).catch((err) => {
      console.log("error", err);
      showNotification({
        title: "Не удалось загрузить логотип",
        description: err?.response?.data?.error || "Произошла ошибка при загрузке логотипа",
        style: "error",
        duration: 5,
      });
    });
  };

  const handleUploadLogo = async () => {
    if (file) {
      return await getLogoLink();
    } else {
      if (!editApp) {
        return appForm.logo;
      } else {
        return editApp.logo;
      }
    }
  };

  const onFinish: FormProps<AppFormType>["onFinish"] = (values) => {
    onSubmit(values);
  };

  const onFinishFailed: FormProps<AppFormType>["onFinishFailed"] = () => {
    showNotification({
      title: "Форма не заполнена",
      description: "Заполните форму правильно!",
      style: "error",
      duration: 3,
    });
  };

  const onSubmit = async (payload: AppFormType) => {
    setLoadingOverlay(true);
    // @ts-ignore
    const logo: string = await handleUploadLogo();

    const newAppInfo: AppPayloadType = {
      names: {
        ru: payload.nameRu,
        kz: payload.nameKz,
        en: payload.nameEn,
      },
      descriptions: {
        ru: payload.descriptionRu,
        kz: payload.descriptionKz,
        en: payload.descriptionEn,
      },
      bridge_methods: payload.bridgeMethods,
      link: payload.link,
      logo: logo || payload.logo,
    };

    if (editApp) {
      if (editApp.mode === "production") {
        try {
          await updateMiniApp(editApp.id, newAppInfo);
          showNotification({
            title: "Приложение отправлено на модерацию",
            description: "Введённые изменения будут доступны после модерации",
            style: "success",
            duration: 3,
          });
        } catch (err: any) {
          showNotification({
            title: "Не удалось внести изменения",
            description: err?.response?.data?.error || "Произошла ошибка при изменении данных приложения",
            style: "error",
            duration: 5,
          });
        }
      } else {
        try {
          await editMiniApp(editApp.id, newAppInfo);
          showNotification({
            title: "Приложение успешно обновлено",
            description: "Введённые изменения сохранены",
            style: "success",
            duration: 3,
          });
        } catch (err: any) {
          showNotification({
            title: "Не удалось внести изменения",
            description: err?.response?.data?.error || "Произошла ошибка при изменении данных приложения",
            style: "error",
            duration: 5,
          });
        }
      }
    } else {
      try {
        await addMiniApp(newAppInfo);
        showNotification({
          title: "Приложение успешно добавлено",
          description: "Теперь вы можете увидеть его в списке ваших приложений",
          style: "success",
          duration: 3,
        });
      } catch (err: any) {
        showNotification({
          title: "Не удалось добавить приложение",
          description: err?.response?.data?.error || "Произошла ошибка при добавлении приложения",
          style: "error",
          duration: 5,
        });
      }
    }
    await initGetMiniApps();
    closeAdding();
    setLoadingOverlay(false);
  };

  const closeAdding = () => {
    setEditApp(undefined);
    navigate("/", { replace: true });
  };

  useEffect(() => {
    if (window.location.pathname === "/edit_app") {
      if (editApp) {
        document.title = "Cabinet | Редактирование";
        setAppForm(() => {
          return {
            nameRu: editApp.names.ru,
            nameKz: editApp.names.kz,
            nameEn: editApp.names.en,
            descriptionRu: editApp.descriptions.ru,
            descriptionKz: editApp.descriptions.kz,
            descriptionEn: editApp.descriptions.en,
            bridgeMethods: editApp.bridge_methods,
            link: editApp.link,
            logo: editApp.logo,
            logo_url: editApp.logo_url,
          };
        });
        form.setFieldsValue({
          nameRu: editApp.names.ru,
          nameKz: editApp.names.kz,
          nameEn: editApp.names.en,
          descriptionRu: editApp.descriptions.ru,
          descriptionKz: editApp.descriptions.kz,
          descriptionEn: editApp.descriptions.en,
          bridgeMethods: editApp.bridge_methods,
          link: editApp.link,
          logo: editApp.logo,
          logo_url: editApp.logo_url,
        });
      } else {
        closeAdding();
      }
    } else {
      document.title = "Cabinet | Добавить приложение";
    }
  }, [editApp]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ width: "80%", margin: "40px auto" }}>
      <Flex align="center" justify="space-between">
        <Button type="text" onClick={closeAdding}>
          <ArrowLeftOutlined /> Назад
        </Button>
      </Flex>
      <h3>{editApp ? "Редактирование" : "Отправить заявку на размещение в каталоге"}</h3>
      <Form form={form} initialValues={appForm} onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete="off">
        <Title level={4}>Название приложения</Title>
        <Form.Item<AppFormType>
          label="Название на русском"
          name="nameRu"
          rules={[{ required: true, message: "Введите название на русском!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item<AppFormType>
          label="Название на казахском"
          name="nameKz"
          rules={[{ required: true, message: "Введите название на казахском!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item<AppFormType>
          label="Название на английском"
          name="nameEn"
          rules={[{ required: true, message: "Введите название на английском!" }]}
        >
          <Input />
        </Form.Item>

        <Title level={4}>Краткое описание</Title>
        <Form.Item<AppFormType>
          label="Описание на русском"
          name="descriptionRu"
          rules={[{ required: true, message: "Введите описание на русском!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item<AppFormType>
          label="Описание на казахском"
          name="descriptionKz"
          rules={[{ required: true, message: "Введите описание на казахском!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item<AppFormType>
          label="Описание на английском"
          name="descriptionEn"
          rules={[{ required: true, message: "Введите описание на английском!" }]}
        >
          <Input />
        </Form.Item>

        <Title level={4}>Адрес приложения (https:// - обязательно)</Title>
        <Form.Item<AppFormType>
          label="URL"
          name="link"
          rules={[
            {
              required: true,
              pattern: isValidUrl,
              message: "Введите корректный URL!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Title level={4}>Лого приложения (ссылка)</Title>
        <Text type="secondary">Формат: Квадратный, максимальный размер 640x640px. Только JPG/JPEG</Text>
        {!changeLogo ? (
          <Flex>
            <Avatar
              src={editApp ? editApp.logo_url : appForm.logo_url}
              shape="square"
              size={102}
              style={{ marginBottom: 8 }}
            />
          </Flex>
        ) : (
          <Upload {...uploadProps}>
            {fileList?.length === 0 && (
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Сменить</div>
              </div>
            )}
          </Upload>
        )}
        <Button style={{ width: 102 }} onClick={handleLogoChange}>
          {changeLogo ? "Отмена" : "Изменить"}
        </Button>

        <Title level={4}>Методы приложения</Title>
        <Form.Item name="bridgeMethods">
          <Checkbox.Group
            className="flex-column"
            options={bridgeMethodList.map((method) => ({
              label: `${method.name} - ${method.description}`,
              value: method.name,
            }))}
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            {editApp ? "Сохранить" : "Отправить заявку"}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
