import { useEffect } from "react";
import { Skeleton, Card, Flex } from "antd";
import AppItem from "components/app_item";
import { useSharedState } from "sharedStateProvider";

export default function AppList({ isSkeleton }: { isSkeleton: boolean }) {
  const { miniApps } = useSharedState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {miniApps?.length ? (
        miniApps.map((appItem, index: number) => <AppItem key={index} app={appItem} />)
      ) : isSkeleton ? (
        [1, 2, 3].map((item: number) => (
          <Card key={item}>
            <Skeleton avatar={true} paragraph={false} />
          </Card>
        ))
      ) : (
        <Flex vertical align="center" justify="center" style={{ height: "50vh" }}>
          Вы не добавили ни одно мини приложение
        </Flex>
      )}
    </>
  );
}
