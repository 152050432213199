import { Api } from "api";

export const getBridgeMethods: any = async (page?: number) => {
  const url = "/mini_apps/v1/bridge_methods";
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  const res = await Api.get(url, { params: { page }, headers });
  return res.data;
};
