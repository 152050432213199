import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Flex, Button, Skeleton } from "antd";
import { useSharedState } from "sharedStateProvider";
import AppList from "components/app_list";

export default function MainPage() {
  const navigate = useNavigate();

  const {
    logout,
    me,
    initGetMe,
    initGetMiniApps,
    setLoadingOverlay,
    showNotification,
  } = useSharedState();

  const [isSkeleton, setSkeleton] = useState<boolean>(true);

  const initMainPage = async () => {
    try {
      setLoadingOverlay(true);
      await Promise.all([initGetMe(), initGetMiniApps()]);
      setLoadingOverlay(false);
      setSkeleton(false);
    } catch (err: any) {
      setLoadingOverlay(false);
      setSkeleton(false);
      err.response.status !== 401 &&
        showNotification({
          title: "Ошибка",
          description:
            err?.response?.data?.error || "Не удалось загрузить данные",
          style: "error",
          duration: 5,
        });
    }
  };

  useEffect(() => {
    document.title = "Cabinet | Главная";
    initMainPage();
  }, []);

  return (
    <Flex vertical gap={24} style={{ width: "80%", margin: "40px auto" }}>
      <Flex align="center" justify="end" gap={16}>
        <div style={{ minWidth: 100, textAlign: "end", fontSize: 16 }}>
          {isSkeleton ? (
            <Skeleton paragraph={false} />
          ) : (
            <span>
              {me?.name} {me?.lastname}
            </span>
          )}
        </div>
        <Button onClick={logout}>Выйти</Button>
      </Flex>
      <Flex vertical gap={24}>
        <Flex align="center" justify="space-between">
          <h1 style={{ margin: 0 }}>Мои приложения</h1>
          <Button onClick={() => navigate("/create_app")}>
            Добавить приложение
          </Button>
        </Flex>
        <Flex vertical gap={12}>
          <AppList isSkeleton={isSkeleton} />
        </Flex>
      </Flex>
    </Flex>
  );
}
