import { useState, useEffect } from "react";
import { Drawer, Flex, Button, Form, Input, Skeleton, Tag } from "antd";
import { getAppNotifications, createNotification } from "api/notifications";
import { PushItemType } from "components/push_list/types";
import { useSharedState } from "sharedStateProvider";
import PushItem from "components/push_item";
import { QuestionCircleOutlined } from "@ant-design/icons";

export default function PushList({
  showPushList,
  hidePushList,
  appId,
}: {
  showPushList: boolean;
  hidePushList: () => void;
  appId: string | undefined;
}) {
  const { showNotification } = useSharedState();

  const [pushList, setPushList] = useState<PushItemType[]>([]);
  const [pushListLoading, setPushListLoading] = useState<boolean>(true);
  const [submitPushLoading, setSubmitPushLoading] = useState<boolean>(false);
  const [newPushModal, setNewPushModal] = useState<boolean>(false);

  const [title, setTitle] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [toUrl, setToUrl] = useState<string>("");

  const onTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };
  const onMessage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  };
  const onToUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
    setToUrl(event.target.value);
  };

  const initNotifications = async () => {
    const res = await getAppNotifications(appId);
    setPushList(res.data);
    setPushListLoading(false);
  };

  const createNewPush = async () => {
    try {
      setSubmitPushLoading(true);
      const payload = {
        title: title,
        message: message,
        to_url: toUrl,
      };
      await createNotification(appId, payload);
      await initNotifications();
      cancelCreatePush();
      showNotification({
        title: "PUSH отправлен",
        description: "Вы успешно отправили PUSH уведомление",
        style: "success",
        duration: 3,
      });
    } catch (err) {
      cancelCreatePush();
      showNotification({
        title: "PUSH не отправлен",
        description: "Не удалось отправить PUSH уведомление",
        style: "error",
        duration: 3,
      });
    }
  };

  const cancelCreatePush = () => {
    setTitle("");
    setMessage("");
    setToUrl("");
    setNewPushModal(false);
    setSubmitPushLoading(false);
  };

  useEffect(() => {
    if (showPushList) {
      initNotifications();
    }
  }, [showPushList]);

  return (
    <>
      <Drawer
        open={showPushList}
        title="PUSH УВЕДОМЛЕНИЯ"
        footer={
          <Button block disabled={pushListLoading} onClick={() => setNewPushModal(true)}>
            Новое PUSH уведомление
          </Button>
        }
        onClose={hidePushList}
        styles={{ body: { display: "flex", flexDirection: "column" } }}
      >
        <Tag color="success" style={{ margin: "0 0 16px" }}>
          <QuestionCircleOutlined style={{ marginRight: 7 }} />
          <a href="https://docs.bridge.robi.group/methods/notifications/" target="_blank" rel="noopener noreferrer">
            Как отправить таргетированное push-уведомление
          </a>
        </Tag>
        {pushListLoading ? (
          <Skeleton />
        ) : pushList.length ? (
          <Flex vertical flex={1} gap={16}>
            {pushList.map((pushItem, index: number) => (
              <PushItem item={pushItem} key={index} />
            ))}
          </Flex>
        ) : (
          <Flex vertical align="center" justify="center" flex={1}>
            Список уведомлений пуст
          </Flex>
        )}
        <Drawer open={newPushModal} title="НОВОЕ PUSH УВЕДОМЛЕНИЕ" onClose={cancelCreatePush} destroyOnClose>
          <Form disabled={submitPushLoading} labelCol={{ span: 7 }}>
            <Form.Item label="Заголовок *">
              <Input onChange={onTitle} />
            </Form.Item>
            <Form.Item label="Сообщение *">
              <Input onChange={onMessage} />
            </Form.Item>
            <Form.Item label="Ссылка">
              <Input onChange={onToUrl} />
            </Form.Item>
          </Form>
          <Flex justify="end">
            <Button disabled={submitPushLoading} onClick={createNewPush}>
              Отправить
            </Button>
          </Flex>
        </Drawer>
      </Drawer>
    </>
  );
}
