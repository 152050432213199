import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import InputMask from "react-input-mask";
import { Flex, Button, Input, Card, Typography } from "antd";
import { getAuthLink, getToken } from "api/auth";
import { useSharedState } from "sharedStateProvider";
import MaskedInput from "../masked_input";
import "./AuthPage.css"; // Подключаем стили

const { Title, Text } = Typography;

export default function AuthPage() {
  const navigate = useNavigate();
  const { setLoadingOverlay, showNotification } = useSharedState();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [phone, setPhone] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [passwordVisible, setPasswordVisible] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");

  const removeNonNumericCharacters = (input: string): string => {
    return input.replace(/[^\d]/g, "");
  };

  const onPhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const cleanedPhoneNumber = removeNonNumericCharacters(event.target.value);
    setPhone(cleanedPhoneNumber);
  };

  const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };


  const getLink = async () => {
    try {
      setIsLoading(true);
      const payload = { phone: phone, redirect_uri: process.env.REACT_APP_REDIRECT_URI };
      const res = await getAuthLink(payload);
      window.open(res.link, "_self");
    } catch (err: any) {
      showNotification({
        title: "Ошибка перенаправления",
        description:
            err?.response?.data?.error ||
            "Не удалось перенаправить данные в Aitu Passport",
        style: "error",
        duration: 3,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const login = async () => {
    try {
      setLoadingOverlay(true);
      await getToken({ phone: phone, password: password});
      navigate("/", { replace: true });
      setLoadingOverlay(false);
    } catch (err: any) {
      setLoadingOverlay(false);
      showNotification({
        title: "Авторизация не пройдена",
        description:
            err?.response?.data?.error || "Произошла ошибка во время авторизации",
        style: "error",
        duration: 10,
      });
    }
  };

  // useEffect(() => {
  //   code && login();
  // }, [code]);

  useEffect(() => {
    document.title = "Cabinet | Авторизация";
  }, []);

  return (
      <div className="auth-page">
        <Card className="auth-card">
          <Title level={3} className="auth-title">Вход в кабинет</Title>
          <Text className="auth-subtitle">
            Для входа в личный кабинет мини-приложений требуется авторизация
          </Text>

          <Flex vertical align="center" gap={16} style={{ width: "100%" }}>
            <MaskedInput
                mask="+7-(999)-999-99-99"
                maskChar="_"
                value={phone}
                alwaysShowMask={true}
                onChange={onPhoneChange}
            />
            <Input.Password
                placeholder="Пароль"
                onChange={onPasswordChange}
                visibilityToggle={{ visible: passwordVisible, onVisibleChange: setPasswordVisible }}
            />
            <Button
                type="primary"
                block
                onClick={login}
                loading={isLoading}
                disabled={phone.length !== 11 || password.length < 4}
                className="auth-button"
            >
              Авторизация
            </Button>
          </Flex>
        </Card>
      </div>
  );
}
